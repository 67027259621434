import React, {useState} from 'react'

import { slideConfig } from '../SliderConfig';

import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css';

import './synq.css'
import BottomButton from '../../components/buttons/BottomButton';

import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import { useMediaQuery } from '../../utils/mediaQueryHook';

gsap.registerPlugin(ScrollToPlugin)

function Synq() {

const [transition, setTransition] = useState(false);

  const change = () => {
    setTransition(!transition);
     document.body.parentElement.classList.toggle("stop-scrolling");
  };

const downSlide = () => {
gsap.to(window, {duration: 2, scrollTo:".cv-section-LIV-slide.V1"});
};


let buttonBottom = {
 position: 'absolute',
 bottom: '10vh',
//  left: cal(100vw - 50%),
    //  transform: 'translate(-50%, -50%)',
};

//uso el nuevo Hook
const isMobile = useMediaQuery("(max-width: 800px)");

if (isMobile) {
buttonBottom = {
 position: 'absolute',
 bottom: '15%',
//  cursor: 'pointer',
//     margin: 'auto',
//     maxWidth: '270px',
//     width: 'auto',
};
};


  return (
  <>
  
    <Slide {...slideConfig } onStartChange={change} onChange={change}>

    <div class="cv-section-Synq-slide V1 slide">
        
        <div class="cv-section-Synq-jobtitle jobtitle">
            <h3>Current - 2022 | SEM Account Manager @ SYNQ</h3>
        </div>
        <div class="cv-section-Synq-ad">
            <span>Ad • Brands-with-Hearts</span>
            <h4>Making Brands Memorable. Crafting Perfect Ads.</h4>
            <p>Establishing relationships between brands and people since 2019. Solid Strategies & Captivating Ads. Analytical | Passionate. Slide right ahead.</p>
        </div>
            <div class="cv-section-Synq-quote quote">
                <p>This website was created with HTML, CSS and Pure Vanilla</p>
            </div>
    </div>

    <div class="cv-section-Synq-slide V2 slide">
       
        <div class="cv-section-Synq-about">
           <p>
            I'm currently part of the SYNQ team working as a Search Engine Marketing account manager, creating strategies and content for a portfolio of B2B and B2C
            Brands. 
            </p>
            
            <p className='mobile'> My duties centre on increasing performance; budget planning, ad creation, reporting, website optimisation, and tracking implementation.
            </p>

        </div>
       <BottomButton text="Discover my earlier role in Legal Marketing at the Law Institute of Victoria." style={{color: 'rgb(218 218 218)'}} downSlide={downSlide} position={buttonBottom}/>

    </div>

</Slide>

  </>
  )
}

export default Synq