import React from "react";
import "./buttombuttom.css";
import leftArrow from "../../assets/images/buttons/ArrowAsset 1Left.svg";
import buttomArrow from "../../assets/images/buttons/ArrowsArtboard 1 copy 2.svg";
import rightArrow from "../../assets/images/buttons/ArrowAsset 3right.svg";

function BottomButton(props) {
  console.log(props.position)
  return (
    <>
           <button style={props.position} className="button bottom" onClick={props.downSlide}>
          <p style={props.style}>{props.text}</p>
          <span />
          <img src={buttomArrow} alt="" />
        </button>
         </>
  );
}

export default BottomButton;