import React, { useState } from 'react'

import { slideConfig } from '../SliderConfig'


import picFankenslogo from './images/Fankens Logo.png'

import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css';

import './fankens.css';


import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import BottomButton from '../../components/buttons/BottomButton'
import { useMediaQuery } from '../../utils/mediaQueryHook';

gsap.registerPlugin(ScrollToPlugin);




function Fankens() {

  const [transition, setTransition] = useState(false);

  const downSlide = () => {
gsap.to(window, {duration: 2, scrollTo:".cv-section-DL-slide.V1"});
};

  const change = () => {
    setTransition(!transition);
     document.body.parentElement.classList.toggle("stop-scrolling");
  };

  let buttonBottom = {
 position: 'absolute',
 bottom: '5vh',
};

//uso el nuevo Hook
const isMobile = useMediaQuery("(max-width: 800px)");

if (isMobile) {
buttonBottom = {
 position: 'absolute',
 bottom: '15%',
};
};


  return (
  <>
  
    <Slide {...slideConfig} onStartChange={change} onChange={change}>
    <div className="cv-section-Fankens-slide V1 slide">
      
        <div className="cv-section-Fankens-jobtitle jobtitle">
            <h3>2020 - 2023 | Freelance @ Fankens</h3>
        </div>
        <img src={picFankenslogo} alt=""/>
        <div className="cv-section-Fankens-quote quote">
            <p>Fankens logo designed<br/>by Ramon Parajua<i></i></p>
        </div>
    </div>

    <div className="cv-section-Fankens-slide V2 slide">
      <BottomButton text="Before diving into online marketing I practiced as a rockwork artisan." downSlide={downSlide} style={{color:'rgb(0 0 0)'}} position={buttonBottom}/>
     
        <div className="cv-section-Fankens_V2-about">
        <p>Fankens was born out of love for creating ads.</p>
         <p>
        My passion for advertising and collaborating with clients to create
        their brands and improve their web visibility led to the start of my freelance work and marked the inception of my journey as an Search Engine Specialist.</p>
        </div>
        </div>

   </Slide>

  
  </>
  )
}

export default Fankens