import React, {useState} from 'react'

import BottomButton from '../../components/buttons/BottomButton';

import { slideConfig } from '../SliderConfig';

import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css';

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import './liv.css'
import { useMediaQuery } from '../../utils/mediaQueryHook';


gsap.registerPlugin(ScrollToPlugin);

function Liv() {

const [transition, setTransition] = useState(false);

  const change = () => {
    setTransition(!transition);
     document.body.parentElement.classList.toggle("stop-scrolling");
  };

const downSlide = () => {
gsap.to(window, {duration: 2, scrollTo:".cv-section-TV-slide.V1"});
};

let buttonBottom = {
 position: 'absolute',
 bottom: '10vh',
//  left: cal(100vw - 50%),
    //  transform: 'translate(-50%, -50%)',
};

//uso el nuevo Hook
const isMobile = useMediaQuery("(max-width: 800px)");

if (isMobile) {
buttonBottom = {
 position: 'absolute',
 bottom: '15%',
};
};


  return (
  <>
  
    <Slide {...slideConfig} onStartChange={change} onChange={change}>

    <div className="cv-section-LIV-slide V1 slide">
      
    

        <div className="cv-section-LIV-jobtitle jobtitle">
            <h3>2021-2022 | Digital Marketing @ LIV</h3>
        </div>
        <div className="cv-section-LIV-quote quote">
            <p>Artwork inspired by the LIV aesthetics</p>
        </div>
    </div>

    <div className="cv-section-LIV-slide V2 slide">
     
          <BottomButton text="Prior to working at the LIV I did a complete brand overhaul at Trade View" downSlide={downSlide} position={buttonBottom}/>
      


        <div className="cv-section-LIV-about">
            <p>I worked for the Law Institute of Victoria developing, tracking and optimising multi-channel content marketing
            strategies; placing an emphasis on website optimisation, SEO/SEM, email campaigns and display advertising.
            </p>
            <p className='mobile'>
            Projects I worked on include the creation of a new company website from concept to release, an increased membership year by a net 3%, 200+ empowerment initiatives, and Coordinated the Diverse Voices in the Law publication.
        </p>
        </div>
    </div>
    </Slide>
   
   </>
  )
}

export default Liv