import React from 'react';
import { Helmet } from 'react-helmet';

const SerpImageSchema = () => {
    const jsonLdData = {
        "@context": "http://schema.org",
        "@type": "ImageObject",
        "contentUrl": "https://axeltaussig.com/static/media/Axel-Taussig-Icon.1c45a4bfd1853e990e5b.png",
        "description": "Digital Marketing Specialist - Creator. Free of dogmas, prompted by love, reason and self-discovery.",
        "name": "Axel Taussig",
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
        </Helmet>
    );
}

export default SerpImageSchema;