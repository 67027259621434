import React, {useState} from "react";

import { slideConfig } from '../SliderConfig'

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import "./intro.css";
import Buttons from "../../components/buttons/Buttons";
import BottomButton from "../../components/buttons/BottomButton";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import Media from 'react-media';
import { useMediaQuery } from "../../utils/mediaQueryHook";

gsap.registerPlugin(ScrollToPlugin);

function Intro() {

const [transition, setTransition] = useState(false);

const downSlide = () => {
gsap.to(window, {duration: 2, scrollTo:".cv-section-Synq-slide.V1"});
};

const buttonStyles = {
  position: "absolute",
    transform: 'translate(-50%, -50%)',
    bottom: '0vh',
    left: '0vw',
}


  const change = () => {
    setTransition(!transition);
     document.body.parentElement.classList.toggle("stop-scrolling");
  };

  //Syles for the button
  const pStyles = {
   margin: 'auto',
}

let buttonBottom = {
 position: 'absolute',
 bottom: '10vh',
};

//uso el nuevo Hook
const isMobile = useMediaQuery("(max-width: 800px)");

if (isMobile) {
buttonBottom = {
 position: 'absolute',
 bottom: '15%',
};
};


  return (
  <>
  
    <Slide {...slideConfig} onStartChange={change} onChange={change}>
        <div className="cv-section-Intro-slide V1 slide">
        

          <div className="cv-section-Intro-jobtitle jobtitle">
            <h3>Digital Marketing Specialist</h3>
          </div>
          <div className="cv-section-Intro-about-V1">
            <p>My experiences, discoveries and passions.</p>
          </div>
          <div className="cv-section-Intro-quote quote">
            <p>
              This website is currently under construction - created with HTML,
              CSS and Vanilla JS
            </p>
          </div>
        </div>

        <div className="cv-section-Intro-slide V2 slide">
         <BottomButton text="Read about my current role as an Online Advertising Manager" downSlide={downSlide} position={buttonBottom}/>
          <div className="cv-section-Intro-about-V2">
           
            <p style={pStyles}>
              My passion is my profession. I'm a digital marketing expert with experience working on diverse creative projects from concept to realisation. My vision is to inspire you, to spark up your soul.
            </p>
            {/* <p>
             In my day-to-day, I cultivate inspiring ideas and nurture the synergy of collaboration to transform them into reality.
            </p> */}
            <p style={pStyles}>
            Explore my work experiences by navigating down the timeline. Let the page guide you through my professional journey.
            </p>
            </div>
          </div>
         
      
      </Slide>
    </>
  );
}

export default Intro;
