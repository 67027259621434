import React, {useState} from 'react';

import { slideConfig } from '../SliderConfig'

import picKookaburra from './images/DL-imageArtboard 2HQ_V2.jpg'
import picWaterfall from './images/DL-imageArtboard 3HQ_V2.jpg'

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import './dl.css';


import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import BottomButton from "../../components/buttons/BottomButton";
import { useMediaQuery } from '../../utils/mediaQueryHook';

gsap.registerPlugin(ScrollToPlugin);


function Dl() {

const [transition, setTransition] = useState(false);

const downSlide = () => {
gsap.to(window, {duration: 2, scrollTo:{y:".cv-section-Piedras-slide.V1", offsetY:45}});
};

  const change = () => {
    setTransition(!transition);
     document.body.parentElement.classList.toggle("stop-scrolling");
  };

    let buttonBottom = {
 position: 'initial',
 bottom: '10vh',
 paddingTop: '20px',
 textAlign: 'left',
margin: 'auto',

 
};

//uso el nuevo Hook
const isMobile = useMediaQuery("(max-width: 800px)");


if (isMobile) {
buttonBottom = {
 position: 'relative',
 bottom: '15%',
 textAlign: 'center',
margin: 'auto',
};
};

  return (
  <>
  
    <Slide {...slideConfig} onStartChange={change} onChange={change}>

    <div className="cv-section-DL-slide V1 slide">
     
        <div className="cv-section-DL-jobtitle jobtitle">
            <h3>2019 | Rock Artisan @ Design Landscapes</h3>
            <p></p>
        </div>
        <div className="cv-section-DL-quote quote">
            <p>Rockwork & Aivery<br/>Wildlife Retreat at Taronga</p>
        </div>
    </div>

    <div className="cv-section-DL-slide V2 slide">
  
        <div className="cv-section-DL_V2-about">
            <div className="cv-section-DL_V2-about-image1 mobile">
               <img src={picWaterfall} alt=""/> 
            </div>
            <div className="cv-section-DL_V2-about-image2 mobile">
            <img src={picKookaburra} alt=""/>
            </div>
            <div className="cv-section-DL_V2-about-text">

           <p>For a span of four years, I worked on designing and sculpting rockworks for landscape architecture and interior architecture. 
            </p>
            <br />
            <p>Here in Australia I took part in a project at Taronga Zoo that
        centered on the landscape architecture of the Wild Life Retreat exhibit, and prior to that, I had gained significant experience from co-founding PIEDRAS, a Rockwork Studio in Buenos Aires, Argentina. I'll share more details about that below.</p>
        <br/>
        <p className='mobile'>In Sydney at the Wild Life Retreat exhibit I collaborated with master rock artisans, contributing to the construction of the structure and the detailing of
        the water features. Working with them felt like a realisation to all my prior research efforts.</p>
        <br/>
        <p className="mobile tablet">The image on the right highlights the primary waterfall at the retreat, sculpted and unpainted. Meanwhile, the image on
        the left captures the avian enclosure under our construction and the beauty of our natural world. Exemplified by
        the towering giraffes and the iconic Kookaburra.</p>
        
        <p><BottomButton text="Read about PIEDRAS" downSlide={downSlide} style={{color:'rgb(54 54 54)'}} position={buttonBottom}/></p>
       

            </div>
        </div>
    </div>

    </Slide>

</>
  )
}

export default Dl